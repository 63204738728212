import React, { useState, useEffect, useContext } from "react";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./ProductCardSale.css";
import Heart_Component from "../Heart_Component/Heart_Component";
import Button_Common from "../Button_Common/Button_Common";
// import { Rating } from "react-simple-star-rating";
import { Rating } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Bottom_Successfull_Modal from "../Common_Modal/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import { Context } from "../../../utils/context";
import Compare_Component from "../Compare_Component/Compare_Component";
import Warning_Modal from "../Warning_Modal/Warning_Modal";
import { UserType } from "../../../utils/common";

const ProductCardSale = ({
  id,
  image,
  title,
  short_description,
  price,
  mrp,
  discount_percentage,
  average_rating,
}) => {
  const [rating, setRating] = useState(0);
  const [compareModalShow, setCompareModalShow] = useState(false);
  const [wishlistModalShow, setWishlistModalShow] = useState(false);
  const navigate = useNavigate();

  const handleRating = (rate) => {
    setRating(rate);
  };

  const onPointerEnter = () => {
    // console.log("Enter");
  };
  const onPointerLeave = () => {
    // console.log("Leave");
  };
  const onPointerMove = (value, index) => {
    // console.log(value, index);
  };

  useEffect(() => {
    if (compareModalShow) {
      // console.log("Compare modal shown");
      const timer = setTimeout(() => {
        // console.log("Navigating to /comparelist");
        setCompareModalShow(false);
        navigate("/comparelist");
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [compareModalShow, navigate]);

  useEffect(() => {
    if (wishlistModalShow) {
      const timer = setTimeout(() => {
        setWishlistModalShow(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [wishlistModalShow]);

  // ------------------------------------------------------------------------------------------------
  const { IMG_URL, wishlist, compare, usertype, signin } = useContext(Context);

  //

  const [warning, setWarning] = useState(false);
  const AlertPopUp = async () => {
    setWarning({
      show: true,
      onHide: () => setWarning(false),
      message: "Please Login as B2B Customer",
    });

    setTimeout(() => {
      setWarning(false);
    }, 2000);
  };

  const handleClick = () => {
    navigate(`/sample-roll/${id}`);
  };

  return (
    <>
      <section className="ProductCardSale">
        <Card>
          <div className="Product-card">
            <div className="Main">
              <div className="Image-Tape">
                <div
                  onClick={() => {
                    navigate(`/sample-roll/${id}`);
                    // signin
                    //   ? usertype == UserType.B2BCustomer
                    //     ? navigate(`/sample-roll/${id}`)
                    //     : AlertPopUp()
                    //   : AlertPopUp();
                  }}
                >
                  <img src={IMG_URL + image} className="tape" alt="Product" />
                </div>
              </div>
              <div className="heart">
                <Heart_Component
                  status={wishlist?.some((item) => item.id === id)}
                  product_id={id}
                />
                <Compare_Component
                  status={compare?.some((item) => item.id === id)}
                  product_id={id}
                />
                {/* <div
                  className="compare mt-1"
                  onClick={() => setCompareModalShow(true)}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assests/Products/compress.png"
                    }
                    className="tape"
                  />
                </div> */}
              </div>
            </div>
            <div className="triangle-element">
              <div className="sale-off">
                <p className="off">
                  {discount_percentage}% <br /> off
                </p>
              </div>
            </div>
          </div>
          <Card.Body>
            <p className="sub-text">{title}</p>
            <p className="sub-text">{short_description}</p>
            <div className="price">
              <p className="sale-price">{price}</p>
              <p className="main-price">
                <span className="real-price">{mrp} </span>
                <br />
                (Inclusive of all taxes)
              </p>
            </div>
            <div className="rating">
              <p className="sub-text">Ratings:</p>
              {/* <Rating
                onClick={handleRating}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
                onPointerMove={onPointerMove}
              /> */}

              <Rating value={average_rating} disabled />
            </div>
            <div className="Cart-Btn mt-2">
              <div className="cart">
                <Button_Common
                  icon={faCartPlus}
                  text="Add to Cart"
                  className="cart sub-text"
                  onClick={handleClick}
                  // onClick={() => setWishlistModalShow(true)}
                />
              </div>
              <div className="buy">
                {/* <Link to={"/buy-now"}> */}
                <Button_Common
                  icon={faCartPlus}
                  text="Buy Now"
                  className="buy sub-text"
                  onClick={handleClick}
                />
                {/* </Link> */}
              </div>
            </div>
          </Card.Body>
        </Card>
        <Bottom_Successfull_Modal
          show={wishlistModalShow}
          onHide={() => setWishlistModalShow(false)}
          icon={faCircleCheck}
          text="Product added to the cart successfully!"
        />
        <Bottom_Successfull_Modal
          show={compareModalShow}
          onHide={() => setCompareModalShow(false)}
          icon={faCircleCheck}
          text="Product is Added to Compared List Successfully!"
        />
      </section>
      <Warning_Modal
        show={warning.show}
        onHide={warning.onHide}
        message={warning.message}
      />
    </>
  );
};

export default ProductCardSale;
