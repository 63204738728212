import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./TopPicks.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper/modules";
import ProductCard from "../../CommonComponent/ProductCard/ProductCard";
import { getTopPicksProducts } from "../../../utils/apis/home";
import { Context } from "../../../utils/context";
import { UserType } from "../../../utils/common";

const TopPicks = () => {
  const product = [
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
  ];

  // ------------------------------------------------------------------------------------------------
  // getTopPicksProducts
  const { usertype } = useContext(Context);

  const [products, setproducts] = useState([]);
  const getData = async () => {
    const res = await getTopPicksProducts();
    if (res.success) {
      setproducts(res?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="TopPicks">
        <Container>
          <h1 className="title-main">
            <span className="main-title-border">Top Pic</span>ks
          </h1>
          <Swiper
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              486: {
                slidesPerView: 1.5,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1400: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
              1900: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
            }}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {products?.map((item, index) => (
              <SwiperSlide key={index}>
                <ProductCard
                  id={item.id}
                  image={item.image}
                  title={item.name}
                  discount_percentage={item.discount_percentage}
                  short_description={
                    item?.variant.find(() => true)?.variant_name
                  }
                  price={
                    usertype == UserType.B2BCustomer
                      ? item?.variant.find(() => true)?.btb_price
                      : item?.variant.find(() => true)?.btc_price
                  }
                  mrp={item?.variant.find(() => true)?.mrp}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </section>
    </>
  );
};

export default TopPicks;
