import React, { useContext, useEffect, useState } from "react";
import ProductInformation from "./ProductInformation/ProductInformation";
import ProductDescription from "./ProductDescription/ProductDescription";
import ProductSlider from "./ProductSlider/ProductSlider";
import WhyUs from "./WhyUs/WhyUs";
import AdhesiveQuality from "./AdhesiveQuality/AdhesiveQuality";
import CustomerReviews from "./CustomerReviews/CustomerReviews";
import Relevent_Product from "./Relevent_Product/Relevent_Product";
import { getProductDetail } from "../../utils/apis/Product/Product";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../utils/context";
import { useForm } from "react-hook-form";
import { product_type } from "../../utils/common";
const ProductDetailPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ---------------------------------------------------------------------------
  const navigate = useNavigate();
  const { id } = useParams();
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState({});
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [singleVariant, setSingleVariant] = useState(false);
  const [MultipleVariant, setMultipleVariant] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const getData = async () => {
    const res = await getProductDetail(id);
    setSingleVariant(false);
    setMultipleVariant(false);
    if (res.success) {
      setData(res?.data);
      reset(res.data);
      setValue("available_pincode_status", false);
      // console.log(res.data.type);
      setValue("minimum_quantity", res.data?.min_quantity);
      if (res.data.type == "1") {
        setValue("variant", res.data?.p_as[0]?.attribute?.name);
        setValue("variant_details", res.data?.p_as[0]?.p_a_vs);
        setValue("mrp", res.data?.p_as[0]?.p_a_vs[0]?.variant?.mrp);
        setValue("btb_price", res.data?.p_as[0]?.p_a_vs[0]?.variant?.btb_price);
        setValue("btc_price", res.data?.p_as[0]?.p_a_vs[0]?.variant?.btc_price);
        setValue("stock", res.data?.p_as[0]?.p_a_vs[0]?.variant?.stock);
        setValue("p_a_v_id", res.data?.p_as[0]?.p_a_vs[0]?.variant?.p_a_v_id);
        setValue(
          "p_a_v_a_v_id",
          res.data?.p_as[0]?.p_a_vs[0]?.variant?.p_a_v_a_v_id
        );
        setValue(
          "product_id",
          res.data?.p_as[0]?.p_a_vs[0]?.variant?.product_id
        );
        setValue("active1", res.data?.p_as[0]?.p_a_vs[0]?.id);
        setSingleVariant(true);
        setImages(res.data?.p_as[0]?.p_a_vs[0].variant.variant_images);
        setSelectedImage(
          res.data?.p_as[0]?.p_a_vs[0].variant.variant_images[0]
        );
      } else if (res.data.type == "2") {
        setValue("variant", res.data?.p_as[0]?.attribute?.name);
        setValue("variant_details", res.data?.p_as[0]?.p_a_vs);
        setValue("active1", res.data?.p_as[0]?.p_a_vs[0]?.id);

        setValue(
          "variant_one",
          res.data?.p_as[0]?.p_a_vs[0]?.p_a_v_as[0]?.attribute?.name
        );

        setValue(
          "variant_one_details",
          res.data?.p_as[0]?.p_a_vs[0]?.p_a_v_as[0]?.p_a_v_a_vs
        );

        setValue(
          "mrp",
          res.data?.p_as[0]?.p_a_vs[0]?.p_a_v_as[0]?.p_a_v_a_vs[0]?.variant?.mrp
        );
        setValue(
          "btb_price",
          res.data?.p_as[0]?.p_a_vs[0]?.p_a_v_as[0]?.p_a_v_a_vs[0]?.variant
            ?.btb_price
        );
        setValue(
          "btc_price",
          res.data?.p_as[0]?.p_a_vs[0]?.p_a_v_as[0]?.p_a_v_a_vs[0]?.variant
            ?.btc_price
        );
        setValue(
          "stock",
          res.data?.p_as[0]?.p_a_vs[0]?.p_a_v_as[0]?.p_a_v_a_vs[0]?.variant
            ?.stock
        );
        setValue(
          "p_a_v_id",
          res.data?.p_as[0]?.p_a_vs[0]?.p_a_v_as[0]?.p_a_v_a_vs[0]?.variant
            ?.p_a_v_id
        );
        setValue(
          "p_a_v_a_v_id",
          res.data?.p_as[0]?.p_a_vs[0]?.p_a_v_as[0]?.p_a_v_a_vs[0]?.variant
            ?.p_a_v_a_v_id
        );
        setValue(
          "product_id",
          res.data?.p_as[0]?.p_a_vs[0]?.p_a_v_as[0]?.p_a_v_a_vs[0]?.variant
            ?.product_id
        );

        setValue(
          "active2",
          res.data?.p_as[0]?.p_a_vs[0]?.p_a_v_as[0]?.p_a_v_a_vs[0]?.id
        );

        setMultipleVariant(true);
        setImages(
          res.data?.p_as[0]?.p_a_vs[0]?.p_a_v_as[0]?.p_a_v_a_vs[0]?.variant
            .variant_images
        );
        setSelectedImage(
          res.data?.p_as[0]?.p_a_vs[0]?.p_a_v_as[0]?.p_a_v_a_vs[0]?.variant
            .variant_images[0]
        );
      }
    } else {
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  // console.log(getValues());

  const onChangeVariant = async (data, type = "1") => {
    // console.log(data);
    // console.log(data?.id);
    if (type == "1") {
      setValue("mrp", data?.variant?.mrp);
      setValue("btb_price", data?.variant?.btb_price);
      setValue("btc_price", data?.variant?.btc_price);
      setValue("stock", data?.variant?.stock);
      setValue("p_a_v_id", data?.variant?.p_a_v_id);
      setValue("p_a_v_a_v_id", data?.variant?.p_a_v_a_v_id);
      setValue("product_id", data?.variant?.product_id);
      setValue("active1", data?.id);
      setImages(data?.variant?.variant_images);
      setSelectedImage(data?.variant?.variant_images[0]);
      // CalculateMOQ();
      // reset(getValues());
    } else if (type == "2") {
      setValue("mrp", data?.variant?.mrp);
      setValue("btb_price", data?.variant?.btb_price);
      setValue("btc_price", data?.variant?.btc_price);
      setValue("stock", data?.variant?.stock);
      setValue("p_a_v_id", data?.variant?.p_a_v_id);
      setValue("p_a_v_a_v_id", data?.variant?.p_a_v_a_v_id);
      setValue("product_id", data?.variant?.product_id);
      setValue("active2", data?.id);
      setImages(data?.variant?.variant_images);
      setSelectedImage(data?.variant.variant_images[0]);
      // CalculateMOQ();
      // reset(getValues());
    }
    CalculateMOQ();
    // reset(getValues());
  };

  // console.log(getValues("moq_id"));
  // console.log(getValues("moq"));

  // const CalculateMOQ = async () => {
  //   if (getValues("product_type") == product_type.B2B) {
  //     if (getValues("product_min_order_qties").length) {
  //       setValue(`moq_id`, getValues("product_min_order_qties")[0].id);
  //       setValue(`moq`, getValues("product_min_order_qties")[0]);
  //       console.log(getValues("product_min_order_qties")[0].id);
  //       getValues("product_min_order_qties")?.map(async (item, index) => {
  //         // if (!index) {
  //         //   console.log(index);

  //         //   setValue(`moq_id`, item.id);
  //         //   setValue(`moq`, item);
  //         // }
  //         const discount = (getValues("btb_price") / 100) * item.percentage;
  //         const price = getValues("btb_price") - discount;
  //         setValue(`product_min_order_qties.${index}.price`, price);
  //       });
  //       reset(getValues());
  //     }
  //   }
  // };

  const CalculateMOQ = async () => {
    const productType = getValues("product_type");
    const productMinOrderQties = getValues("product_min_order_qties");
    const btbPrice = getValues("btb_price");

    if (productType === product_type.B2B && productMinOrderQties.length) {
      const firstMOQ = productMinOrderQties[0];

      // if (!getValues("moq_id")) {
      //   setValue("moq_id", firstMOQ.id);
      //   setValue("moq", firstMOQ);
      // }

      setValue("moq_id", firstMOQ.id);
      setValue("min_quantity", firstMOQ.quantity);
      setValue("moq", firstMOQ);

      for (const [index, item] of productMinOrderQties.entries()) {
        const discount = (btbPrice / 100) * item.percentage;
        const price = btbPrice - discount;
        setValue(`product_min_order_qties.${index}.price`, price);
      }

      reset(getValues());
    }
  };

  useEffect(() => {
    CalculateMOQ();
  }, [getValues("btb_price"), data]);

  const onSubmit = async (data) => {};

  // console.log(images);

  // average_rating
  const [average_rating, setaverage_rating] = useState(0);
  return (
    <>
      <ProductSlider
        average_rating={average_rating}
        data={data}
        register={register}
        errors={errors}
        setError={setError}
        clearErrors={clearErrors}
        getValues={getValues}
        setValue={setValue}
        MultipleVariant={MultipleVariant}
        singleVariant={singleVariant}
        onChangeVariant={onChangeVariant}
        images={images}
        setImages={setImages}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        reset={reset}
        CalculateMOQ={CalculateMOQ}
      />
      <ProductInformation
        data={data}
        getValues={getValues}
        setValue={setValue}
        MultipleVariant={MultipleVariant}
        singleVariant={singleVariant}
        reset={reset}
      />
      <ProductDescription
        data={data}
        getValues={getValues}
        setValue={setValue}
        MultipleVariant={MultipleVariant}
        singleVariant={singleVariant}
        reset={reset}
      />
      {/* <WhyUs  data={data}
        getValues={getValues} setValue={setValue}   reset={reset}
        MultipleVariant={MultipleVariant}
        singleVariant={singleVariant}
      /> */}
      {/* <AdhesiveQuality  data={data}
        getValues={getValues} setValue={setValue}
        MultipleVariant={MultipleVariant}
        singleVariant={singleVariant}   reset={reset}
      /> */}
      <CustomerReviews
        data={data}
        getValues={getValues}
        setValue={setValue}
        MultipleVariant={MultipleVariant}
        singleVariant={singleVariant}
        reset={reset}
        setaverage_rating={setaverage_rating}
      />
      <Relevent_Product
        data={data}
        getValues={getValues}
        setValue={setValue}
        MultipleVariant={MultipleVariant}
        singleVariant={singleVariant}
        reset={reset}
      />
    </>
  );
};

export default ProductDetailPage;
