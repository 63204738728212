import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Countdown from "react-countdown";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper/modules";
import ProductCard from "../../CommonComponent/ProductCard/ProductCard";
import CountUp from "react-countup";
import ProductCardSale from "../../CommonComponent/ProductCardSale/ProductCardSale";
import { AllProductsFilter } from "../../../utils/apis/Product/Product";
import { UserType } from "../../../utils/common";
import { Context } from "../../../utils/context";
function Relevent_Product({ data }) {
  const product = [
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
  ];

  // ------------------------------------------------------------------------------------------------
  // AllProductsFilter

  const { usertype } = useContext(Context);

  const [relatedProducts, setRelatedProducts] = useState([]);
  const getData = async () => {
    const res = await AllProductsFilter(
      data?.category_id,
      "",
      "",
      "",
      "",
      data?.product_type
    );
    if (res.success) {
      setRelatedProducts(res?.data);
    }
  };

  useEffect(() => {
    getData();
  }, [data]);

  return (
    <>
      <section className="salelive_section">
        <Container>
          <div className="salelive_main_div">
            <div className="toptext_div ">
              <h2 className="title-main mb-3">
                <span className="main-title-border ">Relevant items cust</span>
                omers are likely to buy
              </h2>
            </div>

            <div className="swipe_div_main">
              <Swiper
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  486: {
                    slidesPerView: 1.5,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1400: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                  },
                  1900: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                  },
                }}
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {relatedProducts?.data?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <ProductCard
                      id={item.id}
                      average_rating={item.average_rating}
                      image={item.image}
                      title={item.name}
                      discount_percentage={item.discount_percentage}
                      short_description={
                        item?.variant.find(() => true)?.variant_name
                      }
                      price={
                        usertype == UserType.B2BCustomer
                          ? item?.variant.find(() => true)?.btb_price
                          : item?.variant.find(() => true)?.btc_price
                      }
                      mrp={item?.variant.find(() => true)?.mrp}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Relevent_Product;
