import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
// import Permision_Delete_Modal from "../CommonComponents/Common_Modal/Permision_Delete_Modal/Permision_Delete_Modal";
import { useNavigate } from "react-router-dom";
import Permision_Delete_Modal from "../CommonComponent/Common_Modal/Permision_Delete_Modal/Permision_Delete_Modal";
import LogOut from "../CommonComponent/Common_Modal/LogOut/LogOut";
import Successfull_Modal from "../CommonComponent/Common_Modal/Successfull_Modal/Successfull_Modal";
import useRazorpay from "react-razorpay";
import {
  getSeletedCart,
  updateCartCount,
} from "../../utils/apis/addToCart/addToCart";
import {
  OrderApplyCoupon,
  OrderBuyNowCheckout,
  OrderCheckout,
} from "../../utils/apis/orderProcess/orderProcess";
import { Context } from "../../utils/context";
import { cuppon_type } from "../../utils/common";
import Cookies from "js-cookie";
import classNames from "classnames";
const ConfirmOrder = ({ handlePrevious, handleNext, ...props }) => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [ordermodalclose, setOrdermodalclose] = useState(false);
  const [deletepermissionShow, setdeletepermissionShow] = React.useState(false);

  const navigateClick = () => {
    setOrdermodalclose(true);
    setTimeout(() => {
      setOrdermodalclose(false);
      navigate("/your-orders");
    }, 3000);
  };
  const orders = [
    {
      image: process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png",
      name: "Brown Tape",
      text: "2 40 Meter,38 Micron, Per Box 72 Nos",
      quantity: "1",
      price: "₹.408",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png",
      name: "Brown Tape",
      text: "2 40 Meter,38 Micron, Per Box 72 Nos",
      quantity: "1",
      price: "₹.408",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png",
      name: "Brown Tape",
      text: "2 40 Meter,38 Micron, Per Box 72 Nos",
      quantity: "1",
      price: "₹.408",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png",
      name: "Brown Tape",
      text: "2 40 Meter,38 Micron, Per Box 72 Nos",
      quantity: "1",
      price: "₹.408",
    },
  ];

  //   const handlePlaceOrder = () => {
  //     setModalShow(true);

  //     setTimeout(() => {
  //       setModalShow(false);
  //       navigate("/your-order");
  //     }, 2000);
  //   };

  //   ----------------------------------------------------------------

  const { IMG_URL, signin, setCart } = useContext(Context);
  const handlePlaceOrder = async () => {
    if (props.getValues("payment_mode") == "online") {
      await handleRazorpay(props.getValues("total"));
    } else if (props.getValues("payment_mode") == "cod") {
      //   console.log(props.getValues("payment_mode"));
      PlaceOrder();
    }
  };

  const PlaceOrder = async (payment_id = "") => {
    // console.log(props.getValues("payment_id"));
    // console.log(payment_id);
    // console.log(props.getValues());
    const res = await OrderBuyNowCheckout(props.getValues());
    // console.log(res);
    if (res.success) {
      setOrdermodalclose(true);
      await Cookies.remove("cmj_buy_now");
      setTimeout(() => {
        setOrdermodalclose(false);
        navigate("/your-orders");
      }, 3000);
    }
  };

  //
  const [Razorpay, openRazorpay] = useRazorpay();
  const handleRazorpay = async (amount) => {
    const options: RazorpayOptions = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(amount) * 100,
      currency: "INR",
      name: "CMJ",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        props.setValue("payment_id", res.razorpay_payment_id);
        PlaceOrder(res.razorpay_payment_id);
        // await setRazorId(res.razorpay_payment_id);
        // finalFunction(res.razorpay_payment_id);
        // console.log(res.razorpay_payment_id);
        // console.log(res, "ttttttttttttttttttttttttttttt");
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  // ----------------------------------------------------------------

  const [selectedcart, setSeletecCart] = useState([]);
  const getData = async () => {
    // const res = await getSeletedCart();
    // setSeletecCart(res.data);
    setSeletecCart(JSON.parse(Cookies.get("cmj_buy_now") || []));
    props.setValue("cart", JSON.parse(Cookies.get("cmj_buy_now") || []));
  };

  useEffect(() => {
    getData();
  }, []);

  //   const {
  //     control,
  //     register,
  //     handleSubmit,
  //     getValues,
  //     setValue,
  //     setError,
  //     reset,
  //     formState: { errors },
  //   } = useForm();

  useEffect(() => {
    TotalCalculations();
  }, [selectedcart]);

  const TotalCalculations = async () => {
    let total_amount = 0;
    let discount = 0;
    let total_tax = 0;
    let shipping = 0;
    let total = 0;

    for (const iterator of selectedcart) {
      if (iterator.status == 1) {
        // total_amount
        // const quantity =
        //   iterator?.type === "single"
        //     ? Number(iterator?.quantity)
        //     : Number(iterator?.quantity) * Number(iterator?.box_unit);

        const quantity = iterator?.quantity;
        const amount = Number(iterator.price) * Number(quantity);
        total_amount = total_amount + amount;

        // console.log(iterator.price);

        // total tax
        const tax = (amount / 100) * Number(iterator.tax_percentage);
        total_tax = total_tax + tax;
        // // total_amount
        // const amount = Number(iterator.price) * Number(iterator.quantity);
        // total_amount = total_amount + amount;
        // // total tax
        // const tax = (amount / 100) * Number(iterator.tax_percentage);
        // total_tax = total_tax + tax;
      }
    }

    total = total_amount + total_tax;

    total = total - discount;

    total = total + shipping;

    props.setValue("total_amount", total_amount);
    props.setValue("discount", discount);
    props.setValue("total_tax", total_tax);
    props.setValue("shipping", shipping);
    props.setValue("total", +total);
    props.reset(props.getValues());
  };

  //
  // OrderApplyCoupon
  const [appyedCoupon, setAppyedCoupon] = useState(false);

  const ApplyCouppon = async () => {
    if (props.getValues("code").length) {
      props.clearErrors();
      // console.log(props.getValues("code"));
      const res = await OrderApplyCoupon({
        code: props.getValues("code"),
        amount: props.getValues("total"),
      });

      if (res.success) {
        let discount = 0;
        let total = Number(props.getValues("total"));
        if (cuppon_type?.percentage == res?.data?.type) {
          // console.log((Number(res?.data?.discount) / 100) * total);
          discount = (Number(res?.data?.discount) / 100) * total;
        } else if (cuppon_type?.fixedamount == res?.data?.type) {
          discount = Number(res?.data?.total_amount);
        }
        props.setValue("code_id", res?.data?.id);
        props.setValue("discount", discount);
        props.setValue("total", total - discount);
        setAppyedCoupon(true);
        props.setValue("coupon_applyed", true);
        props.reset(props.getValues());
      } else {
        props.setError("code", {
          message: res.message,
        });
      }
    } else {
      props.setError("code", {
        message: "Enter Coupon Code",
      });
    }
  };

  return (
    <>
      <section className="OrderReview">
        <div className="Address">
          {/* <Form.Check type="checkbox" label="Select All" /> */}
          <Row className="justify-content-center mt-2">
            <Col xxl={8} xl={8} lg={12} md={12}>
              {selectedcart?.map((item, index) => (
                <div className="Deatils-address" key={index}>
                  {/* <Form.Check type="checkbox" /> */}
                  <div className="OrderDetails">
                    <div className="Details">
                      <Row>
                        <Col xxl={3} xl={3} lg={3} md={3} xs={12} sm={12}>
                          <div className="main-cart">
                            <div className="image-product">
                              <img
                                src={IMG_URL + item.image}
                                alt="product"
                                className="payment-logo"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xxl={7} xl={8} lg={7} md={7} xs={12} sm={12}>
                          <div className="content">
                            <Row>
                              <p className="commmontext">
                                {item.name}{" "}
                                {item?.type === "bulk"
                                  ? "{Bulk Order}"
                                  : "{Single}"}
                              </p>

                              <p className="small-text">{item.text}</p>

                              <p className="small-text">
                                Number of Items : {item.box_unit}
                              </p>

                              <div className="Quantity">
                                <p className="small-text">QTY</p>
                                <div className="quantity-no sub-title d-flex">
                                  <p className="">
                                    {" "}
                                    <span>{item.quantity} </span>
                                  </p>
                                </div>
                                <span> Boxes</span>
                              </div>

                              <div className="d-flex  mt-2">
                                <p className="price commmontext mrptextt">
                                  MRP : ₹ <span>{item.mrp}</span>
                                </p>
                                <p className="price commmontext">
                                  Price : ₹ {item.price}
                                </p>
                              </div>
                              {/* <p className="price commmontext  mt-2">
                                Price : ₹ {item.price}
                              </p> */}
                            </Row>
                          </div>
                        </Col>
                        {/* <Col xxl={2} xl={1} lg={2} md={1} xs={12} sm={12}>
                          <div className="Delete-order">
                            <div className="delete-main">
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="icon"
                                onClick={() => setdeletepermissionShow(true)}
                              />
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                    </div>
                  </div>
                </div>
              ))}
            </Col>
            <Col xxl={4} xl={4} lg={7} md={10}>
              <div className="Order-payment">
                <div className="total-amount">
                  <p className="sub-title">Order Summary</p>
                  <Row>
                    <div className="order-main">
                      <p className="small-text">
                        Price Item({selectedcart?.length}):
                      </p>
                      <p className="small-text">
                        ₹ {props.getValues("total_amount")}
                      </p>
                    </div>

                    <div className="order-main">
                      <p className="small-text">Tax:</p>
                      <p className="small-text">
                        ₹ {props.getValues("total_tax")}
                      </p>
                    </div>

                    <div className="order-main">
                      <p className="small-text">Discount:</p>
                      <p className="small-text">
                        - ₹ {props.getValues("discount")}
                      </p>
                    </div>

                    <div className="order-main">
                      <p className="small-text">Payment Method:</p>
                      <p className="small-text">
                        {" "}
                        {props.getValues("payment_mode")}
                      </p>
                    </div>

                    {/* <div className="order-main">
                      <p className="small-text">Shipping</p>
                      <p className="small-text">₹0</p>
                    </div> */}
                    <div className="order-main">
                      <p className="small-text">Address:</p>

                      <p className="small-text add">
                        {props.getValues("address.add_type")}{" "}
                        {props.getValues("address.address")}{" "}
                        {props.getValues("address.landmark")} -{" "}
                        {props.getValues("address.zip_code")}
                      </p>
                    </div>

                    <div className="order-main">
                      <p className="price-text sub-title">Total</p>

                      <p className="price-text sub-title">
                        ₹ {props.getValues("total")}
                      </p>
                    </div>

                    <div className="apply-coupon-holder">
                      <Form>
                        <div className="row">
                          <div className="col-md-8">
                            <div className="code-field-holder">
                              <Form.Control
                                type="text"
                                {...props.register("code", {
                                  required: "Enter Coupon Code",
                                })}
                                className={classNames("", {
                                  "is-invalid": props.errors?.code,
                                })}
                                placeholder="Enter Coupon Code"
                                onChange={(e) => {
                                  props.setValue("code", e.target.value);
                                  setAppyedCoupon(false);
                                  props.setValue("coupon_applyed", false);
                                  props.setValue(
                                    "total",
                                    Number(props.getValues("total")) +
                                      Number(props.getValues("discount"))
                                  );
                                  props.setValue("discount", 0);
                                  props.reset(props.getValues());
                                }}
                              />
                              {props.errors?.code && (
                                <sup className="text-danger">
                                  {props.errors?.code?.message}
                                </sup>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="Coupon-btn-holder">
                              <Button_Common
                                className="tabs-btn small-text orange"
                                text="Apply Coupon "
                                disabled={props.getValues("coupon_applyed")}
                                onClick={ApplyCouppon}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-8">
                            {appyedCoupon && (
                              <div>
                                <span className="green-text">
                                  Appyed Coupon Successfully
                                </span>
                              </div>
                            )}
                            {/* <div>
                              <span className="red-text">ggd</span>
                            </div>

                            <div>
                              <span className="green-text">ggd</span>
                            </div> */}
                          </div>
                        </div>
                      </Form>
                    </div>
                  </Row>
                  <div className="apply_now_btn order">
                    <Button_Common
                      onClick={handlePrevious}
                      className="back-btn me-3 small-text back"
                      text="BACK"
                    >
                      Back
                    </Button_Common>

                    <Button_Common
                      type="button"
                      // onClick={navigateClick}
                      onClick={handlePlaceOrder}
                      className="tabs-btn small-text orange"
                      text="Confirm Order"
                    >
                      Next
                    </Button_Common>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <LogOut
        show={deletepermissionShow}
        onHide={() => setdeletepermissionShow(false)}
      />

      <Successfull_Modal
        show={ordermodalclose}
        onHide={() => setOrdermodalclose(false)}
        message={"Your Order has been Placed Successfuly"}
      />
    </>
  );
};

export default ConfirmOrder;
