import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import "./CustomerReviews.css";
import ProgressBar from "react-bootstrap/ProgressBar";
// import RatingStar from "../../CommonComponent/RatingStar/RatingStar";
import { Rating } from "@mui/material";
import { useParams } from "react-router-dom";
import { getCustomerRatings } from "../../../utils/apis/Product/Product";

const CustomerReviews = ({ setaverage_rating, ...props }) => {
  const [rating, setRating] = useState(0);
  const [likedReviews1, setLikedReviews1] = useState(new Set());
  const [likedReviews2, setLikedReviews2] = useState(new Set());

  const handleLike1 = (index) => {
    setLikedReviews1((prevLikedReviews) => {
      const updatedLikedReviews = new Set(prevLikedReviews);
      if (updatedLikedReviews.has(index)) {
        updatedLikedReviews.delete(index);
      } else {
        updatedLikedReviews.add(index);
      }
      return updatedLikedReviews;
    });
  };

  const handleLike2 = (index) => {
    setLikedReviews2((prevLikedReviews) => {
      const updatedLikedReviews = new Set(prevLikedReviews);
      if (updatedLikedReviews.has(index)) {
        updatedLikedReviews.delete(index);
      } else {
        updatedLikedReviews.add(index);
      }
      return updatedLikedReviews;
    });
  };
  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    // other logic
  };
  // Optinal callback functions
  const onPointerEnter = () => {
    // console.log("Enter");
  };
  const onPointerLeave = () => {
    // console.log("Leave");
  };
  const onPointerMove = (value, index) => {
    // console.log(value, index);
  };

  const reviews = [
    {
      image: process.env.PUBLIC_URL + "/assests/Reviews/r1.png",
      name: "Rajesh",
      text: "Strong Adhesive as a they described on product Title",
      size: "Size: 2 Inch 30 Meter Pack of 06",
      color: "Colour: Brown Tape",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Reviews/r2.png",
      name: "Amar",
      text: "Strong Adhesive as a they described on product Title",
      size: "Size: 2 Inch 30 Meter Pack of 06",
      color: "Colour: Brown Tape",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Reviews/r3.png",
      name: "Supriya",
      text: "Strong Adhesive as a they described on product Title",
      size: "Size: 2 Inch 30 Meter Pack of 06",
      color: "Colour: Brown Tape",
    },
  ];

  // ----------------------------------------------------------------
  const { id } = useParams();

  const [data, setDate] = useState();
  const getData = async (id) => {
    const res = await getCustomerRatings(id);
    // console.log(id);
    setDate(res.data);
    setaverage_rating(res.data.average_rating);
  };

  // console.log(data);

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [id]);
  return (
    <>
      <section className="CustomerReviews">
        <Container>
          <div className="main-title">
            <Row>
              <Col xxl={8} xl={8} lg={8} md={8} xs={12} sm={12}>
                <h1 className="title-main">
                  <span className="main-title-border ">Customer</span> Reviews
                </h1>
              </Col>
              {/* <Col xxl={4} xl={4} lg={4} md={4} xs={12} sm={12}>
                <div className="review">
                  <div className="Top-Reviews">
                    <p className="text-p me-3">Top reviews</p>
                    <FontAwesomeIcon icon={faFilter} className="icon" />
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
          <Row className="justify-content-center">
            <Col xxl={5} xl={5} lg={5} md={12} xs={12} sm={12}>
              <div className="Rating-title">
                <p className="commmontext">
                  {data?.total_counts} global ratings
                </p>
              </div>

              <div className="Content-Six-Tab">
                {data?.rating_counts?.map((rating, index) => (
                  <div className="rating-progressbar" key={index}>
                    <p className="commmontext">{rating?.name} Star</p>
                    <ProgressBar now={rating?.percentage} variant="secondary" />
                    <p className="commmontext">{rating?.percentage}%</p>
                  </div>
                ))}
                {/* <div className="rating-progressbar">
                  <p className="commmontext">5 Star</p>
                  <ProgressBar now={60} variant="secondary" />
                  <p className="commmontext">56%</p>
                </div>
                <div className="rating-progressbar">
                  <p className="commmontext">4 Star</p>

                  <ProgressBar now={60} variant="secondary" />
                  <p className="commmontext">44%</p>
                </div>
                <div className="rating-progressbar">
                  <p className="commmontext">3 Star</p>
                  <ProgressBar now={60} variant="secondary" />
                  <p className="commmontext">33%</p>
                </div>
                <div className="rating-progressbar">
                  <p className="commmontext">2 Star</p>
                  <ProgressBar now={60} variant="secondary" />
                  <p className="commmontext">24%</p>
                </div>
                <div className="rating-progressbar">
                  <p className="commmontext">1 Star</p>
                  <ProgressBar now={60} variant="secondary" />
                  <p className="commmontext">15%</p>
                </div> */}
              </div>
            </Col>
            <Col xxl={7} xl={7} lg={7} md={12} xs={12} sm={12}>
              <div className="Rating-title">
                <p className="commmontext">Top reviews From India</p>
              </div>

              {data?.top_users?.map((item, index) => (
                <div className="reviews-Card mb-3" key={index}>
                  <div className="card-main">
                    {/* <div className="image-review">
                      <img src={item.image} className="logo" alt={item.name} />
                    </div> */}
                    <div className="content">
                      <div className="star">
                        <p className="commmontext">
                          {item?.user?.first_name} {item?.user?.last_name}
                        </p>
                        <div className="rating-star">
                          <Rating value={item?.ratings} disabled />
                          {/* <Rating
                            onClick={handleRating}
                            onPointerEnter={onPointerEnter}
                            onPointerLeave={onPointerLeave}
                            onPointerMove={onPointerMove}
                          /> */}
                        </div>
                      </div>
                      <div className="comment">
                        <p className="sub-text">
                          {item.title},{item.review_item}
                        </p>
                        <p className="sub-text">{item.description}</p>
                        {/* <p className="sub-text"></p> */}
                      </div>
                      {/* <div className="like">
                        <FontAwesomeIcon
                          icon={faThumbsUp}
                          className={`icon ${
                            likedReviews1.has(index) ? "fill" : ""
                          }`}
                          onClick={() => handleLike1(index)}
                        />
                        <FontAwesomeIcon
                          icon={faThumbsUp}
                          className={`icon2 ${
                            likedReviews2.has(index) ? "fill" : ""
                          }`}
                          onClick={() => handleLike2(index)}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
              {/* {reviews.map((item, index) => (
                <div className="reviews-Card mb-3" key={index}>
                  <div className="card-main">
                    <div className="image-review">
                      <img src={item.image} className="logo" alt={item.name} />
                    </div>
                    <div className="content">
                      <div className="star">
                        <p className="commmontext">{item.name}</p>
                        <div className="rating-star">
                          <Rating
                            onClick={handleRating}
                            onPointerEnter={onPointerEnter}
                            onPointerLeave={onPointerLeave}
                            onPointerMove={onPointerMove}
                          />
                        </div>
                      </div>
                      <div className="comment">
                        <p className="sub-text">{item.text}</p>
                        <p className="sub-text">{item.size}</p>
                        <p className="sub-text">{item.color}</p>
                      </div>
                      <div className="like">
                        <FontAwesomeIcon
                          icon={faThumbsUp}
                          className={`icon ${
                            likedReviews1.has(index) ? "fill" : ""
                          }`}
                          onClick={() => handleLike1(index)}
                        />
                        <FontAwesomeIcon
                          icon={faThumbsUp}
                          className={`icon2 ${
                            likedReviews2.has(index) ? "fill" : ""
                          }`}
                          onClick={() => handleLike2(index)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))} */}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CustomerReviews;
