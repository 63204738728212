import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Common_Button from "../Button_Common/Button_Common";
// import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
import { Rating } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCircleXmark,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Bottom_Successfull_Modal from "../Common_Modal/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import "./Rate_Us_Modal.css";
import { orderRating } from "../../../utils/apis/myAccount/myAccount";
function Rate_Us_Modal(props) {
  const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);

  //   const handleSubmit = () => {
  //     props.onHide();
  //     setSuccessfullModalShow(true);

  //     setTimeout(() => {
  //       setSuccessfullModalShow(false);
  //     }, 1000);
  //   };

  const [previewSrc, setPreviewSrc] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewSrc(reader.result);
        setShowPreview(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    document.getElementById(`fileInput${props.show}`).click();
  };

  const handleImageRemove = () => {
    setPreviewSrc(null);
    setShowPreview(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {},
  });

  const onSubmit = async (data) => {
    try {
      //   console.log(data);

      const sendData = new FormData();
      sendData.append("order_id", props.show);
      sendData.append("review_item", data?.review_item);
      sendData.append("description", data?.description);
      sendData.append("title", data?.title);
      sendData.append("ratings", data?.ratings);
      sendData.append("image", data?.image[0]);
      const res = await orderRating(sendData);
      //   console.log(res);
      reset();
      props.getData();
      props.onHide();
      setSuccessfullModalShow(true);

      setTimeout(() => {
        setSuccessfullModalShow(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  //   console.log(getValues());
  //   console.log(errors);
  return (
    <>
      <Modal
        {...props}
        size="md"
        className="Modal-holder Rate_Us_Modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
       
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Rate Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modbody">
            <div className="row bg-color me-0 ms-0">
              <div className="col-md-12 p-0">
                <div className="row justify-content-center me-0 ms-0  ">
                  <div className="col-md-12">
                    <div className="">
                      <Form>
                        <Form.Group className="mb-3" controlId="">
                          <Form.Control
                            type="text"
                            placeholder="Review this Item*"
                            className={classNames("", {
                              "is-invalid": errors?.review_item,
                            })}
                            {...register("review_item", {
                              required: "review item is required",
                            })}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                          <Form.Control
                            type="text"
                            placeholder="Description*"
                            className={classNames("", {
                              "is-invalid": errors?.description,
                            })}
                            {...register("description", {
                              required: "description is required",
                            })}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                          <Form.Control
                            type="text"
                            placeholder="Title*"
                            className={classNames("", {
                              "is-invalid": errors?.title,
                            })}
                            {...register("title", {
                              required: "Title is required",
                            })}
                          />
                        </Form.Group>

                        <div className="Rate">
                          <p className="mini-text">Give Rate*</p>

                          <Controller
                            name="ratings" // name of the field
                            {...register("ratings", {
                              required: true,
                            })}
                            control={control}
                            render={({ field }) => (
                              <Rating
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.ratings
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                              />
                            )}
                          />
                          {/* <Rating
                            className={classNames({
                              "is-invalid": errors?.ratings,
                            })}
                            {...register("ratings", {
                              required: true,
                            })}
                            value={getValues("ratings")}
                            onChange={(e) => {
                              setValue("ratings", e.target.value);
                              reset(getValues());
                            }}
                          /> */}
                        </div>
                      </Form>
                      <p className="mini-text">Upload Img*</p>

                      <div className="choose-img-section">
                        <div className="camera" onClick={triggerFileInput}>
                          <FontAwesomeIcon icon={faCamera} />
                        </div>
                        <input
                          type="file"
                          id={`fileInput${props.show}`}
                          style={{ display: "none" }}
                          accept="image/*"
                          className={classNames("", {
                            "is-invalid": errors?.image,
                          })}
                          {...register("image", {
                            required: "image is required",
                          })}
                          onChange={handleImageChange}
                        />
                        {showPreview && (
                          <div className="img-preview-holder ms-3">
                            <div className="img-preview">
                              <img
                                src={previewSrc}
                                alt="Preview"
                                className="preview"
                              />
                            </div>
                            <div
                              className="close-mark-holder"
                              onClick={handleImageRemove}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="btn-holder mt-4">
                        <Common_Button
                          className="SubmitBtn green text"
                          text="Submit"
                          //   onClick={handleSubmit}
                          onClick={handleSubmit(onSubmit)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Bottom_Successfull_Modal
        show={modalSuccessfullShow}
        onHide={() => {
          setSuccessfullModalShow(false);
        }}
        text={"Rating Submitted Successfully!"}
      />
    </>
  );
}

export default Rate_Us_Modal;
