import {
  faNoteSticky,
  faTruckFast,
  faBox,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);

  return a;
};

export const RequiredIs = {};

export const cuppon_type = {
  percentage: "percentage",
  fixedamount: "fixedamount",
};

export const payment_options = {
  CustomerType: {
    EndUser: "End User",
    Reseller: "Reseller",
  },
  Repeat: {
    SingleTime: "Single Time",
    RepeatedTime: "Repeated Time",
    NotSure: "Not Sure",
  },
};

export const Order_Status = {
  ordered: 1,
  processing: 2,
  shipped: 3,
  delivered: 4,
  cancelled: 5,
  returned: 6,
  refund: 7,
};

export const Order_Status_icon = {
  1: faNoteSticky,
  2: faBox,
  3: faTruckFast,
  4: faBox,
  5: faBox,
  6: faBox,
  7: faBox,
};

export const product_type = {
  B2B: "Bulk Order",
  B2C: "Home Page",
  SampleRoll: "Sample Roll",
};

export const UserType = {
  B2BCustomer: "B2B Customer",
  B2CCustomer: "B2C Customer",
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function() {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};

export const RegxExpression = {
  name: /[A-Z-a-z ]/,
  email: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
  phone: /[0-9]/,

  ifsc: "^[A-Z]{4}[0][dA-Z]{6}$",
  ifsc_code: /^[A-Z0-9]$/,
  micr: /^[0-9]{9}$/,
  bank_number: /^\d{0,14}$/,
  gst: /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[1-9A-Z]{1}$/,
};

export function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
}

export const calculateFutureDate = (days) => {
  const today = new Date();
  const futureDate = new Date(today.setDate(today.getDate() + days));

  const options = { weekday: "long", day: "numeric", month: "long" };
  return futureDate.toLocaleDateString("en-US", options);
};

export const ModifyDateFormat = (date) => {
  const today = new Date(date);

  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  return today.toLocaleDateString("en-US", options);
};
