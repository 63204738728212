import React, { useContext, useEffect, useState } from "react";
import "./TopPicks.css";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper/modules";
import ProductCard from "../../CommonComponent/ProductCard/ProductCard";
import { Context } from "../../../utils/context";
import { getTopPicksProducts } from "../../../utils/apis/home";
import { UserType } from "../../../utils/common";
function TopPicks() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const product = [
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
  ];

  // ------------------------------------------------------------------------------------------------
  // getTopPicksProducts
  const { usertype } = useContext(Context);

  const [products, setproducts] = useState([]);
  const getData = async () => {
    const res = await getTopPicksProducts();
    if (res.success) {
      setproducts(res?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="toppick_section">
        <Container>
          <div className="top_pick_main">
            <h2 className="title-main mb-3">
              {" "}
              <span className="toptext_span">Top Pic</span>ks
            </h2>

            <div className="swipe_div_main">
              <Swiper
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  486: {
                    slidesPerView: 1.5,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1400: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                  1900: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {products?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <ProductCard
                      id={item.id}
                      image={item.image}
                      title={item.name}
                      discount_percentage={item.discount_percentage}
                      short_description={
                        item?.variant.find(() => true)?.variant_name
                      }
                      price={
                        usertype == UserType.B2BCustomer
                          ? item?.variant.find(() => true)?.btb_price
                          : item?.variant.find(() => true)?.btc_price
                      }
                      mrp={item?.variant.find(() => true)?.mrp}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default TopPicks;
